import {
  Typography,
  Card,
  CardMedia,
  Stack,
  Divider,
  CardHeader,
  Button,
  Grid,
  Box,
  Link,
  DialogContentText,
  TextField,
} from "@mui/material";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { formatTimestamp } from "../common.js";
import Loading from "../components/Loading.js";
import { MobileContext } from "../contexts/MobileContext.js";

function HomePage() {
  const [initial, setInitial] = useState({ phase: "loading" });
  const [events, setEvents] = useState(undefined);
  const [discussion, setDiscussion] = useState(undefined);
  const [numAlbums, setNumAlbums] = useState(undefined);
  const [numGenres, setNumGenres] = useState(undefined);
  const [numMembers, setNumMembers] = useState(undefined);
  const [messages, setMessages] = useState({}); // stores error and success
  const { mobile } = useContext(MobileContext);
  const navigate = useNavigate();

  useEffect(() => {
    // get upcoming events
    let options = {
      method: "GET",
      url: "/api/events",
    };
    axios
      .request(options)
      .then((res) => {
        if (res.data.error) {
          setMessages({
            error: "There was an error retrieving upcoming events.",
          });
          return;
        }
        console.log(res);
        setEvents(res.data.events);
      })
      .catch((err) => {
        console.log(err);
        setMessages({
          error: "There was an error retrieving upcoming events.",
        });
      });

    axios
      .get("/api/get-number-albums")
      .then((res) => {
        setNumAlbums(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setNumAlbums(74);
      });

    axios
      .get("/api/get-number-genres")
      .then((res) => {
        setNumGenres(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setNumGenres(83);
      });

    axios
      .get("/api/get-number-members")
      .then((res) => {
        setNumMembers(res.data.data);
      })
      .catch((err) => {
        console.error(err);
        setNumMembers(183);
      });

    getResults();
    getInitial();
  }, []);

  let phase = initial.phase;
  let nextDisscussion = undefined;
  if (events !== undefined) {
    for (var i = 0; i < events.length; i++) {
      if (events[i].IS_DISCUSSION === true) {
        nextDisscussion = events[i];
        break;
      }
    }
  }
  //if phase < 3 then all we got is the genre of the week
  if (!mobile) {
    return (
      <div>

        <Grid
          container
          sx={{
            direction: "row",
            height: "100%",
            minHeight: "90vh",
          }}
        >
          <Grid
            item
            className="img-bg"
            xs={6.5}
            sx={{
              display: "grid",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "start",
              alignSelf: "stretch",
              maxWidth: "54vw",
              maxHeight: "100%",
            }}
          >
            {phase === undefined && nextDisscussion !== undefined && events && <div>
              <Card
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  width: "40vw",
                  height: "21.5vh",
                }}
              ></Card>

              <Typography
                sx={{
                  paddingLeft: "2%",
                  fontWeight: "bold",
                  color: "#fffef2",
                  textShadow: "black 0 2px",
                }}
                variant="h5"
              >
                join our next
              </Typography>

              <Typography
                sx={{
                  paddingLeft: "1%",
                  paddingBottom: "2.5%",
                  fontWeight: "bold",
                  color: "#fffef2",
                  textShadow: "black -2px 4px, #316f9f -4px 6px, #843a3a -6px 8px",
                  fontSize: "10vw",
                  lineHeight: "90%",
                }}
              >
                {nextDisscussion.GENRE.toLowerCase()}
              </Typography>

              <Typography
                sx={{
                  paddingLeft: "2%",
                  fontWeight: "bold",
                  color: "#fffef2",
                  textShadow: "black 0 2px",
                }}
                variant="h5"
              >
                discussion on {formatTimestamp(nextDisscussion.TIMESTAMP)}
              </Typography>
            </div>}
            {initial.data === undefined && events && <div>
              <Card
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  width: "40vw",
                  height: "21.5vh",
                }}
              ></Card>


              {events.length === 0 && <Typography
                sx={{
                  paddingLeft: "2%",
                  fontWeight: "bold",
                  color: "#fffef2",
                  textShadow: "black 0 2px",
                }}
                variant="h5"
              >
                We have no scheduled events at this time.
              </Typography>}

              {events.length !== 0 && nextDisscussion === undefined &&
                <div>

                  <Typography
                    sx={{
                      paddingLeft: "2%",
                      fontWeight: "bold",
                      color: "#fffef2",
                      textShadow: "black 0 2px",
                    }}
                    variant="h5"
                  >
                    join us for
                  </Typography>
                  <Typography
                    sx={{
                      paddingLeft: "1%",
                      fontWeight: "bold",
                      color: "#fffef2",
                      textShadow: "black -2px 4px, #316f9f -4px 6px, #843a3a -6px 8px",
                      fontSize: "10vw",
                      lineHeight: "100%",
                    }}
                  >
                    {nextDisscussion.NAME}
                  </Typography>

                  <Typography
                    sx={{
                      paddingTop: "2%",
                      paddingLeft: "2%",
                      fontWeight: "bold",
                      color: "#fffef2",
                      textShadow: "black 0 2px",
                    }}
                    variant="h5"
                  >
                    on {formatTimestamp(events[0].TIMESTAMP)} at {events[0].LOCATION}
                  </Typography>
                </div>}
            </div>}
            {phase < 3 && discussion && (<div>
              <Card
                sx={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  width: "40vw",
                  height: "21.5vh",
                }}
              ></Card>

              <Typography
                sx={{
                  paddingLeft: "2%",
                  fontWeight: "bold",
                  color: "#fffef2",
                  textShadow: "black 0 2px",
                  marginBottom: "1%"
                }}
                variant="h5"
              >
                this week's genre:
              </Typography>

              <Typography
                sx={{
                  paddingLeft: "1%",
                  fontWeight: "bold",
                  color: "#fffef2",
                  textShadow: "black 0 2px",
                  fontSize: "10vw",
                  lineHeight: "79%",
                }}
              >
                {initial.thisGenre.toLowerCase()}
              </Typography>

              {phase === 1 && (

                <Button
                  sx={{
                    marginTop: initial.thisGenre.toLowerCase().includes('p') || initial.thisGenre.toLowerCase().includes('y') || initial.thisGenre.toLowerCase().includes('g') || initial.thisGenre.toLowerCase().includes('q') || initial.thisGenre.toLowerCase().includes('j') 
                      ? "5.5%"
                      : "3.5%",
                    fontWeight: "bold",
                    color: "#fffef2",
                    backgroundColor: "#843a3a",
                    textShadow: "black 0 2px",
                    textAlign: "justify",
                    "&:hover": {
                      color: "#843a3a",
                      backgroundColor: "#fffef2",
                      textShadow: "black 0 0.5px",
                    }
                  }}
                  variant="contained"
                  href="/selection"
                  onClick={() => navigate('/selection')}
                >
                  nominate an album
                </Button>
              )}
              {phase === 2 && (
                <Button
                  sx={{
                    marginTop: initial.thisGenre.toLowerCase().includes('p') || initial.thisGenre.toLowerCase().includes('y') || initial.thisGenre.toLowerCase().includes('g') || initial.thisGenre.toLowerCase().includes('q') || initial.thisGenre.toLowerCase().includes('j') 
                      ? "5.5%"
                      : "3.5%",
                    fontWeight: "bold",
                    color: "#fffef2",
                    backgroundColor: "#843a3a",
                    textShadow: "black 0 2px",
                    textAlign: "justify",
                    "&:hover": {
                      color: "#843a3a",
                      backgroundColor: "#fffef2",
                      textShadow: "black 0 0.5px",
                    }
                  }}
                  variant="contained"
                  href="/selection"
                  onClick={() => navigate('/selection')}
                >
                  vote on albums
                </Button>
              )}
            </div>)}
            {phase >= 3 && discussion && (
              <div>
                <Card
                  sx={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                    width: "40vw",
                    height: "21.5vh",
                  }}
                ></Card>

                <Typography
                  sx={{
                    paddingLeft: "2%",
                    fontWeight: "bold",
                    color: "#fffef2",
                    textShadow: "black 0 2px",
                  }}
                  variant="h5"
                >
                  this week's album:
                </Typography>

                <Card
                  sx={{
                    backgroundColor: "#fffef2",
                    borderRadius: "20px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-around",
                    width: "40vw",
                    height: "18vw",
                  }}
                >
                  <CardMedia
                    sx={{
                      width: "14vw",
                      height: "14vw",
                      alignSelf: "center",
                    }}
                    component="img"
                    className="left-shadow"
                    image={discussion.IMAGE}
                  />
                  <Grid
                    sx={{
                      flexDirection: "column",
                      width: "20vw",
                      height: "16vw",
                      alignSelf: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      item
                      sx={{
                        color: "#1b1b1b",
                        fontWeight: "bold",
                        lineHeight: "1.3",
                        paddingTop: "2%",
                        fontSize: "1.6vw",
                      }}
                    >
                      {"title: " + discussion.ALBUM.toLowerCase()}
                      <br />
                      {"artist: " + discussion.ARTIST.toLowerCase()}
                    </Typography>
                    <Typography
                      item
                      sx={{
                        color: "#1b1b1b",
                        fontWeight: "bold",
                        lineHeight: "1.25",
                        fontSize: "1.2vw",
                      }}
                    >
                      {"genre: " + discussion.SUBGENRE.toLowerCase()}
                      <br />
                      {"released: " + discussion.RELEASE_DATE}
                      <br />
                      {discussion.TRACKS && "tracks: " + discussion.TRACKS}
                    </Typography>
                    <Grid
                      item
                      sx={{
                        flexDirection: "row",
                        display: "flex",
                        paddingBottom: "4%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        item
                        sx={{
                          color: "#232323",
                          fontWeight: "lighter",
                          lineHeight: "1",
                          maxWidth: "60%",
                          fontSize: "1.2vw",
                        }}
                      >
                        {"join us on " +
                          formatTimestamp(initial.timestamp).toLowerCase()}
                        {initial.location.toLowerCase().includes("https") ? (
                          <>
                            {" on "}
                            <a
                              href={initial.location}
                              style={{ color: "#843A3A" }}
                            >
                              Zoom
                            </a>
                          </>
                        ) : (
                          " in " + initial.location.toLowerCase()
                        )}
                      </Typography>
                      <CardMedia
                        item
                        sx={{
                          maxWidth: "15%",
                        }}
                        component="img"
                        image={"/logo192.png"}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={5.5}
            sx={{
              display: "grid",
              flexDirection: "column",
              justifyContent: "center",
              alignContent: "start",
              alignSelf: "stretch",
            }}
          >
            <Box
              sx={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                width: "100%",
                height: "11vh",
              }}
            ></Box>
            <Typography
              sx={{
                width: "34vw",
                fontWeight: "bold",
                textAlign: "center",
              }}
              variant="h3"
            >
              welcome to TAMU album of the week!
            </Typography>
            <Typography
              sx={{
                width: "34vw",
                fontStyle: "italic",
                textAlign: "center",
                paddingTop: "1.5vh",
                paddingBottom: "3.5vh"
              }}
              variant="h5"
            >
              your bookclub, for music!
            </Typography>
            {/* <Box
              sx={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                width: "100%",
                height: "5.5vh",
              }}
            ></Box> */}
            <Grid
              sx={{
                height: "100px",
                flexDirection: "row",
                width: "80%",
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                justifySelf: "center",
              }}
            >
              <Grid
                item
                sx={{
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "5.25vw",
                    lineHeight: "110%",
                    width: " 5.25vw",
                  }}
                >
                  {numAlbums}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                  }}
                  variant="h6"
                >
                  albums
                </Typography>
              </Grid>
              <Divider
                sx={{
                  borderRightWidth: "3px",
                  color: "#acacac",
                }}
                orientation="vertical"
              ></Divider>
              <Grid
                item
                sx={{
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  width: " 5.25vw",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "5.25vw",
                    lineHeight: "110%",
                  }}
                >
                  {numGenres}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                  }}
                  variant="h6"
                >
                  genres
                </Typography>
              </Grid>
              <Divider
                sx={{
                  borderRightWidth: "3px",
                  color: "#acacac",
                }}
                orientation="vertical"
              ></Divider>
              <Grid
                item
                sx={{
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  width: "5.25vw",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "5.25vw",
                    lineHeight: "110%",
                  }}
                >
                  {numMembers}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                  }}
                  variant="h6"
                >
                  members
                </Typography>
              </Grid>
            </Grid>
            <Box
              sx={{
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                width: "100%",
                height: "5.5vh",
              }}
            ></Box>
            <Card
              sx={{
                width: "34vw",
                overflowY: "auto",
                marginBottom: "20%",
              }}
            >
              <Card
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  border: "none",
                  boxShadow: "none",
                }}
              >
                <Typography
                  sx={{
                    color: "#fffef2",
                    backgroundColor: "#316f9f",
                    width: "100%",
                    paddingLeft: "31%",
                  }}
                  variant="h6"
                >
                  upcoming events:
                </Typography>
              </Card>
              {events === undefined && <Loading />}
              {events &&
                events.length > 0 &&
                events.map((event) => (
                  <Stack
                    sx={{
                      paddingBottom: "1.5%",
                      paddingTop: "1.5%",
                    }}
                    direction="column"
                  >
                    <Divider
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.1vw",
                      }}
                    >
                      {formatTimestamp(event.TIMESTAMP)}
                    </Divider>
                    <Typography
                      sx={{
                        alignSelf: "center",
                        fontSize: "1.05vw",
                      }}
                    >
                      {event.NAME}
                    </Typography>
                    {event.IS_DISCUSSION && (
                      <Typography
                        sx={{
                          alignSelf: "center",
                          fontSize: "1.05vw",
                        }}
                      >
                        Genre: {event.GENRE}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        alignSelf: "center",
                        fontSize: "1.05vw",
                      }}
                    >
                      Location:{" "}
                      {event.LOCATION.includes("https") ? (
                        <a href={event.LOCATION} style={{ color: "#843A3A" }}>
                          Zoom
                        </a>
                      ) : (
                        event.LOCATION
                      )}
                    </Typography>
                  </Stack>
                ))}
              {events && events.length === 0 && (
                <Typography sx={{ alignSelf: "center" }} variant="h5">
                  No upcoming events
                </Typography>
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }

  if (mobile) {
    return (<>
      {/*the grid that holds the entire page*/}
      <Grid
        className="img-bg"
        sx={{
          display: "grid",
          flexDirection: "column",
          justifyContent: "center",
          alignContent: "start",
          alignSelf: "stretch",
          width: "100vw",
          minHeight: "89vh",
        }}>
        {/* block of empty space */}
        <Card
          item
          sx={{
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
            width: "auto",
            height: "5vh",
          }}
        ></Card>

        {/* header card that has the logo, club stats, etc. */}
        <Card
          item
          sx={{
            height: "120vw",
            width: "85vw",
            backgroundColor: "#fffef2",
            borderRadius: "10px",
            boxShadow: "2px 2px 4px #2e2e2e",
          }}
        >
          {/* grid that holds all the element of the card inside it */}
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {/* logo */}
            <CardMedia
              item
              sx={{
                width: "63%",
                height: "auto",
                paddingTop: "10%",
              }}
              component="img"
              image="logo512.png"
            />

            {/* welcome text */}
            <Typography
              item
              sx={{
                fontSize: "7.5vw",
                fontWeight: "bold",
                lineHeight: "100%",
                textAlign: "center",
                paddingTop: "4%",
                width: "85%",
              }}
            >
              welcome to TAMU album of the week!
            </Typography>

            {/* tagline (if only it was still bookclub for albums... alas) */}
            <Typography
              item
              sx={{
                fontSize: "4vw",
                fontStyle: "italic",
                lineHeight: "100%",
                textAlign: "center",
                width: "85%",
              }}
            >
              your bookclub, for music!
            </Typography>

            {/* grid that holds the club stats: albums, generes, members */}
            <Grid
              sx={{
                flexDirection: "row",
                width: "75%",
                height: "20%",
                display: "flex",
                justifyContent: "space-between",
                justifySelf: "center",
                paddingTop: "10%",
              }}
            >
              <Grid
                item
                sx={{
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  width: "15vw",
                  display: "flex",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "12vw",
                    lineHeight: "80%",
                  }}
                >
                  {numAlbums}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "4vw",
                  }}
                >
                  albums
                </Typography>
              </Grid>

              <Divider
                sx={{
                  borderRightWidth: "3px",
                  color: "#acacac",
                }}
                orientation="vertical"
              ></Divider>

              <Grid
                item
                sx={{
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  width: "15vw",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "12vw",
                    lineHeight: "80%",
                  }}
                >
                  {numGenres}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "4vw",
                  }}
                >
                  genres
                </Typography>
              </Grid>

              <Divider
                sx={{
                  borderRightWidth: "3px",
                  color: "#acacac",
                }}
                orientation="vertical"
              ></Divider>

              <Grid
                item
                sx={{
                  flexDirection: "column",
                  height: "100%",
                  alignItems: "center",
                  display: "flex",
                  width: "15vw",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "12vw",
                    lineHeight: "80%",
                  }}
                >
                  {numMembers}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "bold",
                    color: "#843a3a",
                    fontSize: "4vw",
                  }}
                >
                  members
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Card>

        {/* another block of empty space */}
        <Card
          item
          sx={{
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
            width: "auto",
            height: "5vh",
          }}
        ></Card>

        {/* card for discussion information */}
        <Card
          item
          sx={{
            paddingBottom: "6.5%",
            width: "85vw",
            backgroundColor: "#fffef2",
            borderRadius: "10px",
            boxShadow: "2px 2px 4px #2e2e2e",
          }}
        >
          {/* grid that holds all the discussion information */}
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
              alignContent: "center",
              width: "100%",
              height: "100%",
            }}
          >
            {/* code that runs if discussion exists/ if the discussion useState is not undefined */}
            {discussion && (<>
              {/* if there is no album decided for this week yet, then have the "last week's album: "  text show, if phase < 3 then etc. etc  */}
              {phase === undefined && (
                <Typography
                  item
                  sx={{
                    paddingTop: "6.5%",
                    fontWeight: "450",
                    color: "black",
                    fontSize: "4.5vw",
                    width: "84%",
                    fontStyle: "italic",
                    lineHeight: "110%",
                  }}
                >
                  previous album of the week:
                </Typography>
              )}
              {phase < 3 && (
                <Typography
                  item
                  sx={{
                    paddingTop: "6.5%",
                    fontWeight: "450",
                    color: "black",
                    fontSize: "4.5vw",
                    width: "84%",
                    fontStyle: "italic",
                    lineHeight: "110%",
                  }}
                >
                  last week's album:
                </Typography>
              )}
              {phase >= 3 && (
                <Typography
                  item
                  sx={{
                    paddingTop: "6.5%",
                    fontWeight: "450",
                    color: "black",
                    fontSize: "4.5vw",
                    width: "84%",
                    fontStyle: "italic",
                    lineHeight: "110%",
                  }}
                >
                  this week's album:
                </Typography>
              )}

              {/* AOTW's album cover */}
              <CardMedia
                item
                sx={{
                  width: "80%",
                  height: "auto",
                }}
                component="img"
                className="left-double-shadow"
                image={discussion.IMAGE}
              />

              {/* AOTW's title n artist text */}
              <Typography
                item
                sx={{
                  fontWeight: "bold",
                  color: "black",
                  fontSize: "6.25vw",
                  width: "82%",
                  lineHeight: "117%",
                  paddingTop: "5%",
                }}
              >
                {"title: " + discussion.ALBUM.toLowerCase()}
                <br />
                {"artist: " + discussion.ARTIST.toLowerCase()}
              </Typography>
            </>)}

            {/* grid for discussion time and location detail info */}
            <Grid
              item
              sx={{
                flexDirection: "row",
                display: "flex",
                // paddingBottom: "2%",
                paddingLeft: "9%",
                alignSelf: "start",
                justifyContent: "space-between",
                alignItems: "start",
                width: "98%",
              }}
            >
              {/* you get how this works atp */}
              {phase >= 3 && (
                <>
                  <Typography
                    item
                    sx={{
                      color: "black",
                      fontSize: "4.25vw",
                      lineHeight: "110%",
                      paddingTop: "4%",
                    }}
                  >
                    {"join us on " +
                      formatTimestamp(initial.timestamp).toLowerCase()}
                    {initial.location.includes("https") ? (
                      <>
                        {" on "}
                        <a
                          href={initial.location}
                          style={{ color: "#843A3A" }}
                        >
                          Zoom
                        </a>
                      </>
                    ) : (
                      " in " + initial.location.toLowerCase()
                    )}
                  </Typography>

                  <CardMedia
                    item
                    sx={{
                      maxWidth: "25%",
                    }}
                    component="img"
                    image={"/logo192.png"}
                  />
                </>
              )}

              {phase < 3 && (
                <CardMedia
                  item
                  sx={{
                    maxWidth: "25%",
                    marginLeft: "auto",
                  }}
                  component="img"
                  image={"/logo192.png"}
                />
              )}
            </Grid>
          </Grid>
        </Card>

        {/* more empty space */}
        <Card
          item
          sx={{
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
            width: "auto",
            height: "5vh",
          }}
        ></Card>

        {/* card for events */}
        <Card
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "auto",
            width: "85vw",
            backgroundColor: "#fffef2",
            borderRadius: "10px",
            boxShadow: "2px 2px 4px #2e2e2e",
          }}
        >
          <Typography
            sx={{
              alignSelf: "center",
              width: "auto",
              fontSize: "8.5vw",
              fontWeight: "450",
              fontStyle: "italic",
              paddingTop: "3.5%",
            }}
          >
            upcoming events
          </Typography>
          {/* play the loading animation inside the card if the events aren't defined */}
          {events === undefined && <Loading />}
          {/* map the events to a stack */}
          {events &&
            events.length > 0 &&
            events.map((event) => {
              var times = formatTimestamp(event.TIMESTAMP).split(" ");
              console.log(times)
              return (
                <Stack
                  sx={{
                    paddingBottom: "5%",
                    paddingTop: "3%",
                  }}
                  direction="column"
                >
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "80%",
                      justifyContent: "space-between",
                      alignSelf: "center",
                    }}
                  >
                    <Grid
                      sx={{
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "5vw",
                          textAlign: "end",
                          width: "auto",
                        }}
                      >
                        {times[0].substring(0, 3)}
                      </Typography>

                      <Grid
                        sx={{
                          flexDirection: "row-reverse",
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <Typography
                          item
                          sx={{
                            textAlign: "end",
                          }}
                        >
                          {times[1].substring(times[1].length - 2)}
                        </Typography>

                        <Typography
                          item
                          sx={{
                            fontSize: "10vw",
                            textAlign: "end",
                            fontWeight: "500",
                            lineHeight: "90%",
                          }}
                        >
                          {times[1].substring(0, times[1].length - 2)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Divider
                      sx={{
                        borderRightWidth: "1.75px",
                        bgcolor: "#232323",
                      }}
                      orientation="vertical"
                    ></Divider>
                    <Grid
                      sx={{
                        flexDirection: "column",
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      <Typography
                        item
                        sx={{
                          fontSize: "5vw",
                          fontWeight: "400",
                          width: "43vw"
                        }}
                      >
                        {event.NAME}
                      </Typography>

                      <Typography
                        item
                        sx={{
                          fontSize: "4.25vw",
                          fontWeight: "375",
                          lineHeight: "90%",
                        }}
                      >
                        {times[3] + times[4] + " @ "}{" "}
                        {event.LOCATION.includes("https") ? (
                          <a
                            href={event.LOCATION}
                            style={{ color: "#843A3A" }}
                          >
                            Zoom
                          </a>
                        ) : (
                          event.LOCATION
                        )}
                      </Typography>
                    </Grid>
                  </Grid>

                </Stack>
              );
            })}
          {events && events.length === 0 && (
            <Typography sx={{ alignSelf: "center" }} variant="h5">
              No upcoming events
            </Typography>
          )}
        </Card>

        <Card
          item
          sx={{
            backgroundColor: "transparent",
            border: "none",
            boxShadow: "none",
            width: "auto",
            height: "5vh",
          }}
        ></Card>
      </Grid>
    </>);
  }

  function getInitial() {
    const options = {
      method: "GET",
      url: "/api/initial-data",
    };
    axios
      .request(options)
      .then((res) => {
        setInitial(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function getResults() {
    const options = {
      method: "GET",
      url: "/api/discussion-data",
    };
    axios
      .request(options)
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.message);
          setMessages({ error: res.data.message });
          return;
        }
        setDiscussion(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setMessages({ error: "Error accessing database" });
      });
  }
}

export default HomePage;
