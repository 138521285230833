// Officers for the About page

const Officers = [
  //Advisor
  {
    id: 1,
    name: "Dr. Matthew Campbell",
    role: "Advisor",
    img: "/images/aotw-officer-photos/campbell-head-crop.png",
    commitee: 1
  },

  // EXECUTIVE COMMITTEE

  // President
  {
    id: 2,
    name: "Casey Ear",
    role: "AOTW President",
    img: "/images/aotw-officer-photos/casey-head-crop.png",
    commitee: 2
  },

  // Vice President
  {
    id: 3,
    name: "Jessie Hoepfl",
    role: "AOTW Vice President",
    img: "/images/aotw-officer-photos/jessie-head-crop.png",
    commitee: 2
  },

  // Treasurer
  {
    id: 4,
    name: "Abhi Muppidi",
    role: "AOTW Treasurer",
    img: "/images/aotw-officer-photos/abhi-head-crop.png",
    commitee: 2
  },

  // MARKETING COMMITTEE

  // Chair
  {
    id: 5,
    name: "Alanna Broughton",
    role: "Marketing Committee Chair & Creative Director",
    img: "/images/aotw-officer-photos/alanna-head-crop.png",
    commitee: 3
  },

  // Officers
  {
    id: 6,
    name: "Evie Terrell",
    role: "Marketing Committee Officer & Events Liaison",
    img: "/images/aotw-officer-photos/evie-head-crop.png",
    commitee: 3
  },

  {
    id: 7,
    name: "Vanessa Forbes",
    role: "Marketing Committee Officer & Social Media Manager",
    img: "/images/aotw-officer-photos/vanessa-head-crop.png",
    commitee: 3
  },


  // INTERNAL COMMITTEE

  // Chair
  {
    id: 8,
    name: "Maitri Shekar",
    role: "Internal Committee Chair",
    img: "/images/aotw-officer-photos/maitri-head-crop.png",
    commitee: 4
  },

  // Officers
  {
    id: 9,
    name: "Nikoli Alsman",
    role: "Internal Committee Officer",
    img: "/images/aotw-officer-photos/nikoli-head-crop.png",
    commitee: 4
  },

  {
    id: 10,
    name: "Nicholas Peek",
    role: "Internal Committee Officer",
    img: "/images/aotw-officer-photos/nicholas-head-crop.png",
    commitee: 4
  },

  // OUTREACH COMMITTEE

  // Chair
  {
    id: 11,
    name: "Jason Dobbins",
    role: "Outreach Committee Chair",
    img: "/images/aotw-officer-photos/jason-head-crop.png",
    commitee: 5
  },

  // Officers
  {
    id: 12,
    name: "Jesse Liu",
    role: "Outreach Committee Officer",
    img: "/images/aotw-officer-photos/jesse-head-crop.png",
    commitee: 5
  },

  {
    id: 13,
    name: "Cameron Barnaik",
    role: "Outreach Committee Officer",
    img: "/images/aotw-officer-photos/cameron-head-crop.png",
    commitee: 5
  },

  {
    id: 14,
    name: "Adarsh Mullakara",
    role: "Outreach Committee Officer",
    img: "/images/aotw-officer-photos/adarsh-head-crop.png",
    commitee: 5
  },

  // AUTOMATION COMMITTEE

  // Chair
  {
    id: 15,
    name: "Aayush Sharma",
    role: "Automation Committee Chair",
    img: "/images/aotw-officer-photos/aayush-head-crop.png",
    commitee: 6
  },

  //Reserved for Kieran & Seb

    // Officers
  {
    id: 16,
    name: "Kieran Kahler",
    role: "Automation Committee Officer",
    img: "/images/aotw-officer-photos/kieran-head-crop.png",
    commitee: 6
  },

  {
    id: 17,
    name: "William Aalund",
    role: "Automation Committee Officer",
    img: "/images/aotw-officer-photos/william-head-crop.png",
    commitee: 6
  },
];

export default Officers;
