import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import { Typography, Card, Stack, Box, Grid, ClickAwayListener } from '@mui/material'
import { PieChart, pieArcLabelClasses } from '@mui/x-charts';

import Wheel from '../../components/Wheel'
import Loading from '../../components/Loading'
import { MobileContext } from '../../contexts/MobileContext'


export default function Countdown({initial, setInitial}) {
    const [time, setTime] = useState(null) // holds the time for the countdown
    const [albums, setAlbums] = useState(null) // holds the top voted albums
    const [messages, setMessages] = useState({})
    const [wait, setWait] = useState(false) // if the time has been reached and its waiting for results
    const [wheel, displayWheel] = useState(false) // if the page should display the wheel
    const [passInitial, setPassInitial] = useState(null) // used to pass the initial state to the wheel
    const [selected, setSelected] = useState(null) // used for mobile style when user clicks on the chart

    const { mobile } = useContext(MobileContext)

    

    var initialInterval = 0
    var countdownInterval = 0

    useEffect(() => { // if waiting
        if(!wait) return
        initialInterval = setInterval(() => { // every 10 seconds get initial data to see if phase is updated (waiting for automated update to go off)
            getInitial()
        }, 10000)
    }, [wait])

    useEffect(() => {
        getAlbums() // get top 5 albums
        countdownInterval = setInterval(() => { // every second
            timeDiff() // find time difference
        }, 1000)
    }, [])


    if(!mobile)
        return (
        <Box paddingX='5%' paddingBottom='7%' minHeight='100vh'>
            <Typography variant='h2' textAlign='center' paddingY='2%'>countdown to album announcement</Typography>

            { wait && !wheel && 
                <>
                <Typography variant='h3' textAlign='center'>generating wheel...</Typography>
                <Loading />
                </>
            }

            { albums && wheel && 
                <Wheel albums={albums} initial={passInitial} setInitial={setInitial} />
            }

            {!time && !wait && !wheel && 
                <Loading />
            }
            {time && !wait && !wheel &&
            <Grid alignItems='center' justifyContent='center'>
                <Stack direction='row' alignItems="center" justifyContent="center" spacing={mobile ? 1 : 2}>
                    { time.minutes.toString().padStart(2, '0').split('').map((section) => (
                        <Card justifyContent='center' alignItems='center' display='flex' sx={{padding: '25px'}}><Typography variant='h2'>{section}</Typography></Card>
                    )) }
                    <Typography variant='h2'>:</Typography>
                    { time.seconds.toString().padStart(2, '0').split('').map((section) => (
                        <Card sx={{padding: '25px'}}><Typography variant='h2'>{section}</Typography></Card>
                    )) }
                </Stack>
            </Grid>
            }

            <Typography sx={{color: 'red'}} align='center' variant='h2' id='display'>{messages.error}</Typography>
            { albums &&
            <Box marginTop='4%' width='90%' paddingX='5%'>
                <Typography variant='h3' textAlign='center' paddingBottom='2%'>top albums:</Typography>
                <Stack direction='column' justifyContent='center' >
                    {albums.map((album) => (
                        <Box margin='10px' padding='1%' sx={{backgroundColor: '#843a3a', width: `${album.width}%`}}>
                            <Stack direction='row' position='relative' display='flex' justifyContent='space-between'>
                                { !mobile && 
                                    <img src={album.IMAGE} width={150} />
                                }
                                <Grid alignItems='center' display='flex' flex={1}>
                                    <Typography color='white' variant='h6' marginX='2%'>{album.ARTIST} - {album.ALBUM}</Typography>
                                </Grid>
                                <Grid justifyContent='center' alignItems='center' display='flex' marginRight='2%'>
                                    <Typography textAlign='center' color='white' variant='h6' >{Math.round(album.percent * 100)}%</Typography>
                                </Grid>
                            </Stack>
                        </Box>
                    ))}
                </Stack>
            </Box>
            }
        </Box>
        )

    if(mobile)
        return (
        <Box paddingX='5%' paddingBottom='7%' height='100%' minHeight='100vh'>
            <Typography variant='h2' textAlign='center' paddingY='2%'>countdown to album announcement</Typography>

            { wait && !wheel && 
                <>
                <Typography variant='h3' textAlign='center'>generating wheel...</Typography>
                <Loading />
                </>
            }

            { albums && wheel && 
                <Wheel albums={albums} initial={passInitial} setInitial={setInitial} />
            }

            {!time && !wait && !wheel && 
                <Loading />
            }
            {time && !wait && !wheel &&
            <Grid alignItems='center' justifyContent='center' paddingBottom='5%'>
                <Stack direction='row' alignItems="center" justifyContent="center" spacing={mobile ? 1 : 2}>
                    { time.minutes.toString().padStart(2, '0').split('').map((section) => (
                        <Card justifyContent='center' alignItems='center' display='flex' sx={{padding: '25px'}}><Typography variant='h2'>{section}</Typography></Card>
                    )) }
                    <Typography variant='h2'>:</Typography>
                    { time.seconds.toString().padStart(2, '0').split('').map((section) => (
                        <Card sx={{padding: '25px'}}><Typography variant='h2'>{section}</Typography></Card>
                    )) }
                </Stack>
            </Grid>
            }

            <Typography sx={{color: 'red'}} align='center' variant='h2' id='display'>{messages.error}</Typography>
            { albums && albums.length > 0 && albums[0].percent &&
                <Stack direction='column' width='100%' alignItems='center' justifyContent='center' textAlign='center'>
                <Typography variant='h3' textAlign='center' paddingBottom='2%'>top albums:</Typography>
                <ClickAwayListener onClickAway={() => setSelected(null)}>
                    <Box>
                    <PieChart 
                        series={[{
                            data: albums.map((album, i) => ({id: i, value: album.percent*100, label: `${album.ARTIST} - ${album.ALBUM}`})),
                            highlightScope: { faded: 'global', highlighted: 'item' },
                            faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },

                        }]}
                        colors={['#316f9f']}
                        height={300}
                        width={400}
                        slotProps={{
                            legend: { hidden: 'true' },
                        }}
                        tooltip={{ trigger: 'none' }}
                        margin={{left: 50, right: 50, top: 20}}
                        onItemClick={(e, item) => setSelected(albums[item.dataIndex])}
                    />
                    </Box>
                </ClickAwayListener>

                {selected &&
                <Stack direction='column' justifyContent='center' textAlign='center' alignItems='center'>
                    <Typography variant='h2'>{selected.ALBUM}</Typography>
                    <Typography variant='h3'>{Math.round(selected.percent*100)}%</Typography>
                    <Typography variant='h3'>{selected.score} votes</Typography>
                </Stack>
                }
                </Stack>
            }
        </Box>
        )

    function  timeDiff() {
        let clientTime = new Date() // client time in whatever time zone theyre in

        // get next occurance of update time in UTC
        let target = new Date(clientTime)
        let splitTime = initial.time.split(':')
        target.setHours(splitTime[0])
        target.setMinutes(splitTime[1])
        target.setSeconds(splitTime[2])

        // convert UTC to user timezone
        let offset = target.getTimezoneOffset()
        target = new Date(target.getTime() - offset * 60 * 1000)
        if (target <= clientTime) 
            target.setDate(target.getDate() + 1);

        target.setHours(target.getHours() + 1)
        
        let diff = target - clientTime
        var minutes = Math.floor(diff / 60000); // Divide by 60,000 milliseconds to get minutes
        var seconds = Math.floor((diff % 60000) / 1000); // Use the remainder to get seconds
        setTime({minutes: minutes, seconds: seconds})

        if(seconds < 0 || minutes > 200) {  // negative time = time has past, tell user to wait, large minutes = dont show time yet
            setWait(true)
        }
    
    }

    function getAlbums() {
        let options = {
            method: 'GET',
            url: '/api/top-5-albums'
        }
        axios.request(options).then((res) => {
            if(res.data.error) {
                setMessages({error: 'There was an error getting the top albums'})
                return
            }
            setAlbums(res.data.rows)
        })
    }

    function getInitial() {
        const options = {
          method: 'GET',
          url: '/api/initial-data'
        }
        axios.request(options).then((res) => {
        res.data.phase = 3
        console.log(res)
          if(res.data.phase > 2) { // if album is ready, stop intervals and display wheel
            setPassInitial(res.data)
            clearInterval(initialInterval)
            clearInterval(countdownInterval)
            displayWheel(true)
            setWait(false)
          }
          
        })
        .catch((err) => {
          console.log(err)
        })
      }
}