import { useNavigate } from "react-router-dom";
import { Box, Stack, Card, Button, Dialog, Typography} from '@mui/material'
import { useContext, useState } from "react";
import { MobileContext } from "../contexts/MobileContext";
import NewsletterForm from "./NewsletterForm";
// IF CHANGING ALSO CHANGE THE MIN HEIGHT IN APP.JS TO FIT NEW HEIGHT

function Footer() {

    const { mobile } = useContext(MobileContext)
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };


    return (
        <Box backgroundColor='#843a3a' paddingX={mobile ? '2%' : '0.5%'} paddingRight={mobile ? '3%' : '1%'} height='55px'>
            <Stack direction='row' justifyContent='space-between' alignItems='center' height='100%'>
                <a href="https://tamu.edu"><img src="images/logos/TAMU-logo-white.png" height="45" alt="Link to Texas A&M University"></img></a>
                
                <Stack direction='row' className='more-footer' spacing={4} alignItems='center' justifyContent='center'>

                    <Button onClick={handleOpen}className="newsletter-button" sx={{
                        width: "30px",
                        minWidth: "30px",
                        bottom: "2px",
                    }} >
                        <img src="images/logos/email-read-logo.png"  alt="Newsletter Sign-up pop-up"></img>
                    </Button>

                    <Dialog onClose={handleClose} open={open} sx={{
                        opacity: "97%"
                    }}>
                        {!mobile && <Card sx={{
                            border: "none",
                            boxShadow: "-1vw 1vw 0px #316f9f, -2vw 2vw 0px #843a3a;",
                            width: "25vw",
                            height: "15vh",
                            alignContent: "center",
                            justifyContent: "center",
                            justifyItems: "center",
                            border: 3,
                            borderColor: "#843a3a",
                        }}>
                            <NewsletterForm/>
                        </Card>}
                        {mobile && <Card sx={{
                            border: "none",
                            boxShadow: "-1vw 1vw 0px #316f9f, -2vw 2vw 0px #843a3a;",
                            width: "60vw",
                            height: "20vh",
                            alignContent: "center",
                            justifyContent: "center",
                            justifyItems: "center",
                            border: 3,
                            borderColor: "#843a3a",
                        }}>
                            <NewsletterForm/>
                        </Card>}
                    </Dialog>
                    
                    <a href="https://instagram.com/tamuaotw" target="_blank" rel="noopener noreferrer">
                        <div class="instagram-footer">
                            <img src="images/logos/instagram-logo-white.png" class="instagram-white" alt="Link to Album of the Week Instagram"></img>
                            <img src="images/logos/instagram-logo.png" class="instagram-top" alt="Link to Album of the Week Instagram"></img>
                        </div>
                    </a>
                    
                    <a href="https://discord.gg/vvxFMvp4yR" target="_blank" rel="noopener noreferrer">
                        <div class="discord-footer">
                            <img src="images/logos/discord-logo-white.png" class="discord-white" alt="Link to Album of the Week Discord"></img>
                            <img src="images/logos/discord-logo.png" class="discord-top" alt="Link to Album of the Week Discord"></img>
                        </div>
                    </a>
                    

                    <a href="https://open.spotify.com/user/31xumcyshbbl6bobl63n6h4bpojq?si=2a09104bb77745a1" target="_blank" rel="noopener noreferrer">
                        <div class="spotify-footer">
                            <img src="images/logos/spotify-logo-white.png" class="spotify-white" alt="Link to Album of the Week Spotify"></img>
                            <img src="images/logos/spotify-logo.png" class="spotify-top" alt="Link to Album of the Week Spotify"></img>
                        </div>
                    </a>
                </Stack>
            </Stack>
        </Box>
    )
}

export default Footer