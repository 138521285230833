import React, {useState, useEffect} from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { useContext } from "react";
import { MobileContext } from "../contexts/MobileContext";
import { Typography, TextField, IconButton, InputAdornment } from "@mui/material";
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';



function NewsletterForm(){
    const { mobile } = useContext(MobileContext)

    //i would've set up an env file with this info but it was conflicting weirdly 
    //with react, and it would've only been used for this one component so i
    //jus gave up on the idea if the front end ever needs an env for something else, 
    //then throw this stuff in there too, that would make sense
    const MAILCHIMP_POST_URL = 'https://tamuaotw.us21.list-manage.com/subscribe/post'
    const MAILCHIMP_U = '9a8e7e99d4b44f5f19cddd957'
    const MAILCHIMP_ID = '89ab2faf34'
    
    const MC_postUrl = `${MAILCHIMP_POST_URL}?u=${MAILCHIMP_U}&id=${MAILCHIMP_ID}`

    const [MCemail, setMCEmail] = useState('');


    return (
        <MailchimpSubscribe
            url={MC_postUrl}
            render={({ subscribe, status, message }) => (
            <form className="newsletter-form"
            onSubmit={(e) =>{
                e.preventDefault();
                MCemail &&
                MCemail.indexOf("@") > -1 &&
                subscribe({
                    EMAIL: MCemail
                })
                alert("The form was submitted")
            }}>
                {status === "sending" && 
                <Typography>
                    sending...
                </Typography>
                }
                {(status === "error" || status === "success") &&  
                <Typography>
                    {message}
                </Typography>
                }

                <TextField
                    id="Newsletter_email_TF"
                    label="Join our newsletter!"
                    onChange={(e) => {setMCEmail(e.target.value)}}
                    value={MCemail}
                    defaultValue="your_email@tamu.edu"
                    slotProps={{
                        input: { startAdornment: (
                            <InputAdornment position="start"
                                sx={{
                                    backgroundColor: "#fffef2",
                                }}>
                                <AlternateEmailOutlinedIcon />
                            </InputAdornment>
                        ),},
                    }}
                    required
                    sx={{
                        color: "white",
                        outline: "black",
                    }}
                />

                <IconButton variant='contained' id='submit' type="submit" color="black" disabled={!MCemail || (MCemail.indexOf("@") <= -1 )} sx={{
                    alignSelf: "center",
                    top: "8px"
                }}>
                    <KeyboardReturnIcon/>
                </IconButton>
            </form>
            )}
        />        
    )
}

export default NewsletterForm