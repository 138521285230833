 //all FAQs for the About page
 import { Box, Button, Card, CardMedia, Divider, Grid, List, ListItem, ListItemText, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

 const mobile = window.innerWidth <= 900 //shitty hotfix

 const FAQs = [ 
    {
        id: 1,
        question: "what is album of the week?",
        answer: (
            <>
                <Typography variant="body1" textAlign="left">
                    Album of the week is a community 
                    bonded through a shared love of music. 
                    We host weekly “book club-esque” discussions, 
                    plan concert trips, share music, and hang out!
                </Typography>
            </>
        )
    },

    {
        id: 2,
        question: "what are discussions like?",
        answer: (
            <>
                <Typography variant='body1' textAlign='left'>
                    Discussions are hosted every week, Monday at 8pm in 
                    ARCC 207. We do a last minute listening session usually 
                    an hour before, depending on the album’s length, for 
                    anyone who wants to brush up on album details or hasn’t 
                    found time to check it out!
                </Typography>
                {!mobile &&
                    <CardMedia
                        component="img"
                        src={"/images/aotw_discussion_timeline_graphic.png"}
                        alt={`Timeline Graphic`}
                        title={"Timeline Graphic"}
                        sx={{ margin: 'auto', borderRadius: '16px' }}
                    />
                }
            </>
        )
    },

    {
        id: 3,
        question: "how is the album of the week picked?",
        answer: (
            <>
                <Typography variant='body1' textAlign='left'>
                    Each week a nomination process occurs to determine 
                    the album of the week. It cycles through genres each week, 
                    so there’s always something interesting to talk about! 
                </Typography>
                {!mobile &&
                    <CardMedia
                        component="img"
                        src={"/images/aotw_rotation_and_nomination_graphic.png"}
                        alt={`Rotation Graphic`}
                        title={"Rotation Graphic"}
                        sx={{ margin: 'auto', borderRadius: '16px' }}
                    />
                }
            </>
        )
    },

    {
        id: 4,
        question: "can i nominate something that’s already been discussed?",
        answer: (
            <>
                <Typography variant='body1' textAlign='left'>
                    Sure! Album of the Week is a collection of changing faces, 
                    so a past discussion may not have had any current members 
                    there. With the personal impact of many monumental albums, 
                    we’re always down to rediscuss! 
                </Typography>
            </>
        )
    },

    {
        id: 5,
        question: "do i have to pay dues to join?",
        answer: (
            <>
                <Typography variant='body1' textAlign='left'>
                    No! For the first two discussions, our nomination 
                    process is open to all. Afterward, the aotw <Typography sx={{fontStyle: 'italic', fontWeight: "bold", display: 'inline'}}>Voting Pass</Typography> provides you 
                    access to nominating albums, but they aren’t required 
                    to attend any meetings, socials, or join the Discord! 
                    Feel free to drop by anytime or even bring a friend!
                    <br></br> <br></br>
                    <em>To buy the <Typography sx={{fontStyle: 'italic', fontWeight: "bold", display: 'inline'}}>Voting Pass</Typography>, click the “store” tab to access our FlyWire; if you bought a year-long pass in the Fall, you have the pass in the Spring as well!</em>
                </Typography>
            </>
        )
    },

    {
        id: 6,
        question: "is it too late to join?",
        answer: (
            <>
                <Typography variant='body1' textAlign='left'>
                    No!  Every meeting, listening party, and social 
                    is an opportunity to stop by and join! There’s no 
                    due date for buying a Voting Pass so if you miss the first few meetings, 
                    you’re always invited to pop in and join our community. 
                </Typography>
            </>
        )
    },

    {
        id: 7,
        question: "what can i do if i can't make meetings?",
        answer: (
            <>
                <Typography variant='body1' textAlign='left'>
                    We regularly host listening parties and 
                    social events outside of our regular meeting time, 
                    and we announce them all on our Discord and Instagram!
                </Typography>
            </>
        )
    },

    {
        id: 8,
        question: "can i rate or review albums from past discussions?",
        answer: (
            <>
                <Typography variant='body1' textAlign='left'>
                    Yes! Once you’ve created an account, visiting the 
                    “past discussions” tab and clicking on an album 
                    allows you to rate or leave a review on any previously 
                    discussed album! 
                    <br></br> <br></br>
                    <em>
                        All reviews are publicly viewable; if you don’t wish 
                        to be named on your review, select “anonymous” before 
                        submitting. Post-discussion ratings do not affect the 
                        average rating.
                    </em>
                </Typography>
            </>
        )
    }
 ];

 export default FAQs